import React,{FunctionComponent} from 'react';
import {graphql, PageProps} from 'gatsby';
import ProductLayout from '../../components/layout/ProductLayout/ProductLayout';
import {Box, Stack, styled} from '@mui/material';
import DownloadPdfIcon from '../../icons/DownloadPdfIcon';

type DataType = {
    wpProduct: {
        title: string;
        packaging: string[];
        image: string;
        content: string;
        pdf: string;
        videos: string;
        testProperties: string;
    };
};

const ProductPage: FunctionComponent<PageProps<DataType>> = ({
    data
}) => {
    const product = data.wpProduct;

    return <ProductLayout title={product.title} showCetrificate={true} showBackButton showMenu={false}>
        <Stack spacing={2} direction={{
            xs: 'column',
            md: 'row',
        }}>
            <Box sx={{ maxWidth: '358px' }}>
                {product.image && <Box component={'img'} src={product.image} alt={product.title} sx={{
                    display: 'block',
                    margin: '0 auto',
                    height: 'auto',
                    width: 'auto',
                    maxWidth: '100%',
                    maxHeight: '512px',
                }} />}
            </Box>

            <Box sx={{ flex: 1, color: '#9a9a9a' }}>
                {product.packaging.length > 0 && <Box sx={{ mb: '50px' }}>
                    <Box component={'span'} sx={{
                        fontSize: '13px',
                        lineHeight: '22px',
                        fontStyle: 'italic',
                    }}>Pakiranje:</Box>

                    {product.packaging.map(packaging => <Box key={packaging} component={'span'} sx={{
                        fontWeight: 700,
                        fontSize: '22px',
                        lineHeight: '22px',
                        color: '#000',
                        ml: 2
                    }}>{packaging}</Box>)}
                </Box>}

                <Box dangerouslySetInnerHTML={{ __html: product.content }} sx={{
                    fontSize: '14px',
                    letterSpacing: '0.5px',
                    '& p': { mb: '10px'},
                }} />

                {product.pdf && <LinkWrapper>
                    <Link href={product.pdf}>
                        <DownloadPdfIcon />
                        <span>Informacije o proizvodu</span>
                    </Link>
                </LinkWrapper>}

                {product.testProperties && <LinkWrapper>
                    <Link href={product.testProperties}>
                        <DownloadPdfIcon />
                        <span>Podaci o testiranju</span>
                    </Link>
                </LinkWrapper>}

                {product.videos && <Box dangerouslySetInnerHTML={{
                    __html: product.videos
                }} sx={{
                    '& iframe': {
                        width: '100%',
                    }
                }} />}
            </Box>
        </Stack>
    </ProductLayout>
};

const LinkWrapper = styled('div')({
    marginBottom: '10px',
});

const Link = styled('a')({
    letterSpacing: '0.5px',
    color: '#000',
    fontWeight: 700,
    textDecoration: 'none',
    '&:hover': {
        textDecoration: 'underline',
    },
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    '& svg': {
        color: '#ff0000',
        fontSize: '1.3rem'
    },
});

export const pageQuery = graphql`
query ProductDetails($productSlug: String) {
  wpProduct(slug: {eq: $productSlug}) {
    title
    packaging
    image
    content
    pdf
    videos
    testProperties
  }
}
`;

export default ProductPage;
